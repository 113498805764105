button:focus {
  outline: none;
}

.data-soures-page-table-el{
  max-height: 600px;
  overflow: auto
}

.w-65 {
  width: 65%;
}
.w-35 {
  width: 35%;
}

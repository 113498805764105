@import "../../styles/variables";

.title {
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    width: 30%;
    height: 1.5px;
    background: $main_color;
  }
}

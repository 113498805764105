.projects-page {
  margin-top: 4rem;
  
  .card-class {
    height: 100%;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.6), 0px 1px 1px 0px rgba(0, 0, 0, 0.2),
        0px 1px 3px 0px rgba(0, 0, 0, 0.18);
    }
  }
}

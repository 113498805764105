.MuiPaper-root > div:nth-of-type(2) {
  flex-grow: 1;
}

.datasources {
  .MuiToolbar-root {
    padding-left: 0;
    
    .MuiFormControl-root {
      min-width: 2rem;
    }
  }
}

.users-table {
  // display none button plus
  .MuiToolbar-root
    > div:nth-of-type(3)
    > div:nth-of-type(1)
    > div:nth-of-type(1)
    > span:nth-of-type(1)
    > button:nth-of-type(2),
  .MuiToolbar-root > div:nth-of-type(3) > div:nth-of-type(1) > div:nth-of-type(1) > span:nth-of-type(1) > span {
    display: none;
  }
}

@import "../../styles/variables";

.jsoneditor {
  border: thin solid $main_color;

  .jsoneditor-menu {
    background-color: $main_color;
    border-bottom: 1px solid $main_color;
  }
}

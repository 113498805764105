// COLORS

$light_color: #f5f5f5; // main light color
$main_color: #607d8b; // main color, old: #3f51b5

// General styles

$sidebar_width: 7rem;

:export {
  sidebarWidth: $sidebar_width;
  light_color: $light_color;
}
